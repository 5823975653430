.texteditor {
  .svg-preview {
    width: 100%;
    background: $white;
    svg {
      margin-left: 5%; }

    @media (min-width : 1200px) {
      svg {
        transform-origin: top left;
        transform: scale(1.65); } }

    @media (max-width : 1200px) {
      svg {
        transform-origin: top left;
        transform: scale(1.40); } }

    @media (max-width : 992px) {
      svg {
        transform-origin: top left;
        transform: scale(1.26); } }

    @media (max-width : 768px) {
      svg {
        transform-origin: top left;
        transform: scale(1.05); } }

    @media (max-width : 576px) {
      svg {
        transform-origin: top left;
        transform: scale(0.79); } }

    @media (max-width : 490px) {
      svg {
        transform-origin: top left;
        transform: scale(0.67); } }

    @media (max-width : 370px) {
      svg {
        transform-origin: top left;
        transform: scale(0.63); } } } }
