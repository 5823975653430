.herosearch {
  height: 250px;
  @include media-breakpoint-up(lg) {
    height: 300px; }
  position: relative;
  &__bg {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background: $gray-200;
    @include media-breakpoint-up(lg) {
      height: 300px; }
    @include media-breakpoint-down(md) {
      height: 250px; }
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }

  &__content {
    position: relative;
    padding-top: 80px;
    max-height: 250px;
    @include media-breakpoint-up(lg) {
      padding-top: 100px;
      height: 300px; }
    width: 100%;
    h1 {
      color: $dark;
      @include media-breakpoint-down(sm) {
        font-size: 1.5rem; }
      @include media-breakpoint-down(md) {
        max-width: 70%;
        position: absolute; } }
    p {
      @include media-breakpoint-down(md) {
        margin-top: 15rem; } }
    &__ctas {
      @include media-breakpoint-up(lg) {
        margin-top: 2rem; } } }
  &__navigation {
    font-weight: 600;
    font-size: 110%;
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 0;
      width: 100%; }
    @include media-breakpoint-down(md) {
      margin-top: 1rem; }
    .d-flex {
      justify-content: center;
      flex-wrap: wrap; }
    a {
      padding: 0.5rem 1rem;
      color: $dark;
      text-transform: capitalize; } } }
