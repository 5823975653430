// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

///mixins
@import "styles/mixins/fontface";

@include fontFace('Open Sans', '/assets/fonts/open-sans-v34-latin-600', 600);
@include fontFace('Open Sans', '/assets/fonts/open-sans-v34-latin-700', 700);
@include fontFace('Open Sans', '/assets/fonts/open-sans-v34-latin-regular');
@include fontFace('Signika', '/assets/fonts/signika-v20-latin-600', 600);

//fonts

// theme
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrapvariables";
@import "styles/variables";
// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap";
$rccs-size: 180px;
$rccs-name-font-size: 12px;
$rccs-number-font-size: 13px;
$rccs-valid-font-size: 8px;
$rccs-expiry-font-size: 11px;
$rccs-cvc-font-size: 12px;
@import "../node_modules/react-multi-carousel/lib/styles.css";

// stylesheets containers
@import "styles/containers/Root";
@import "styles/containers/App";
@import "styles/containers/Home";
@import "styles/containers/TextEditor";
@import "styles/containers/SupportCenter";
@import "styles/containers/FourOFour";
@import "styles/containers/ShopCategory";
@import "styles/containers/DesignReview";

//stylesheets components
@import "components/FaqView/FaqView";
@import "components/FaqsListView/FaqsListView";
@import "components/FaqCategoryView/FaqCategoryView";
@import "components/FaqCategoriesListView/FaqCategoriesListView";
@import "components/About/About";
@import "components/CMSBlock/CMSBlock";
@import "components/FieldInput/FieldInput";
@import "components/FileUploadEditorForm/FileUploadEditorForm";
@import "components/FileInput/FileInput";
@import "components/Footer/Footer";
@import "components/Hero/Hero";
@import "components/HeroSearch/HeroSearch";
@import "components/LoadingContent/LoadingContent";
@import "components/Navbar/Navbar";
@import "components/OfflineBar/OfflineBar";
@import "components/Perks/Perks";
@import "components/TextEditorForm/TextEditorForm";
@import "components/TopBar/TopBar";
@import "components/LandingpageContent/LandingpageContent";
@import "components/ShopProductCard/ShopProductCard";
@import "components/ShopProductListItem/ShopProductListItem";
@import "components/CheckoutCart/CheckoutCart";
@import "components/ContactForm/ContactForm";
@import "components/ProductSearch/ProductSearch";

body {
  margin: 0;
  padding: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.xsmall {
  font-size: 55%;
  font-weight: 400; }
.text-big {
  font-size: 0.9rem; }

.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }

.hv-pointer {
  cursor: pointer; }

@import "styles/customstyles";
