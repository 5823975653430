.selected {
  border-color: $primary; }

.product-search {
  .shopcategory__products {
    z-index: 1010;
    max-height: 600px;
    overflow-y: scroll;
    &-attached {
      background: #fff; }
    &-fullpage {
      overflow-y: visible;
      max-height: none; } } }
