.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }

  to {
    transform: rotate(360deg); } }

.product-subnav {
  &.sticky--wrapper {
    > div:first-of-type {
        z-index: 1014; } }
  .nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;
    overscroll-behavior: contain;
    border: 0;
    display: flex;
    border-bottom: 1px solid $ultralight-gray;
    padding-bottom: 0.75rem; }
  .nav-item {
    white-space: nowrap;
    margin-right: 1rem;
    padding-top: 3px;
    &:active, &.active {
      font-weight: bold; }
    &:hover {
      cursor: pointer;
      text-decoration: underline; } }
  .sticky {
    z-index: 1015;
    background: #f8f9fa;
    top: 60px !important;
    height: 40px;
    padding-top: 8px;
    box-shadow: $box-shadow-sm;
    .nav {
      border-bottom: 0;
      padding-bottom: 0; } } }
