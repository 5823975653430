.perks {
  &__imgwrapper {
    width: 3rem;
    height: 3rem;
    overflow: hidden; }
  @include media-breakpoint-down(lg) {
    background-color: $gray-200; } }

.perks_small {
  &__imgwrapper {
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden; } }
