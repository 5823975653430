.topbar {
  background-color: $gray-100;
  border-bottom: 1px solid $gray-300;
  height: $topbar-height;
  .navbar {
    padding-top: 0;
    padding-bottom: 0; }
  &__inquiry {
    flex-grow: 1;
    a {
      padding: 11px; } } }
.dropdown-menu.show {
  z-index: 99999; }
