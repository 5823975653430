#designReviewDialog {
  .solutionCard {
    min-height: 18rem; }
  .notSelected {
    opacity: 0.6; }
  label {
    display: block;
    cursor: pointer; }
  .lineItem-problem-solution-card_input {
    opacity: 0;
    width: 1px;
    margin: 0;
    padding: 0; }
  .customer {
    flex-direction: row-reverse; } }
