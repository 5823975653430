.shopcategory {
  &__products {
    min-height: 30vh; }
  &__mobilefilterbutton {
    @include media-breakpoint-down(md) {
      display: block !important; } }
  &__filterbar {
    @include media-breakpoint-down(md) {
      display: none;
      opacity: 0;
      width: 100%;
      .list-group {
        max-height: 80vh;
        overflow-x: scroll; } }
    &--modalopen {
      display: block;
      background: #ffffff;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3000000;
      opacity: 1;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      padding: 24px 16px; } }
  &__mobilefilterclose {
    @include media-breakpoint-down(md) {
      display: block !important;
      position: fixed;
      bottom: 1rem;
      left: 1rem;
      right: 1rem; } } }
