.footer {
  width: 100%;
  background-color: $blue;
  color: $white;
  &__top {
    background-color: #ddd;
    color: #444;
    padding: 24px 0; }
  &__copyright {
    text-align: center; }
  &__container {
    padding-top: 1rem; }
  &__block {
    &__separator {
      content: "";
      background-color: #00a9df;
      bottom: -12px;
      left: 0;
      width: 40px;
      height: 3px; }
    & ul {
      padding: 0;
      padding-top: 1rem;
      list-style: none;
      li:before {
        content: '\203A';
        text-align: center;
        color: #fff; }
      li a {
        color: $white;
        padding-left: 0.5rem;
        line-height: 2rem;
        font-size: $font-size-sm; } } }
  &__contact {
    padding-top: 1rem;
    font-size: $font-size-sm; } }
