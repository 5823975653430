.lp-product-hero, .lp-hero-fullsize {
  .hero__bg {
    background: rgb(96,91,173);
    background: linear-gradient(346deg, rgba(96,91,173,1) 0%, rgba(99,99,176,1) 35%, rgba(29,146,224,1) 100%); }
  .hero__bg, .hero {
    max-height: 450px; }
  @include media-breakpoint-down(md) {
    .hero {
      padding-top: 250px; }
    .hero__content-floating {
      margin: 0 !important;
      margin-bottom: 2rem !important; } }
  h2 {
    text-align: center; }
  .btn-link {
    background-color: $success;
    color: $white; } }

.lp-product-grid, .lp-product-list, .lp-product-hero {
  h1 {
    font-size: 1.75rem; } }

.lp-hero-fullsize {
  .hero__bg, .hero {
    max-height: 450px; }
  @include media-breakpoint-down(md) {
    .hero {
      height: 500px;
      padding-top: 100px;
      overflow: hidden;
      h1 {
        font-size: 1.35rem; } } } }

.lp-product-grid {
  header {
    .cmsblock__teaser {
      .btn-link {
        background-color: $success;
        color: $white; } } } }
.lp-product-list {
  &__list {
    &-row {
      border-bottom: 1px solid $gray-300; }
    .cmsblock__split {
      h2 {
        font-size: $h3-font-size; }
      .btn {
        float: right;
        margin-right: 2em; } } } }

.category-topbar {
  background-color: $gray-200;
  &-img {
    max-width: 60px;
    max-height: 60px; } }
