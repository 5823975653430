.shop-product-card {
  margin-bottom: 10px;
  .selected {
    border-color: $primary; }
  .card-img-top {
    transition: transform .25s ease; }
  a {
    overflow: hidden; }
  &:hover {
    cursor: pointer;
    box-shadow: $box-shadow;
    background-color: $gray-100;
    .card-img-top {
      transform: scale(1.025); } }
  .sale-price {
    color: $red;
    s {
      color: $secondary;
      margin-left: 5px; } }
  .card-subtitle {
    font-size: $font-size-base; } } // h6
