#supportcenter, #faqcategory, #faq {
  .hero {
    height: 40vH;
    background-color: $primary;
    .hero__content, .hero__bg {
      height: auto; }
    .hero__content {
      text-align: center;
      h1, p, .h1 {
        color: $white;
        max-width: 100%;
        margin-top: auto;
        position: relative; }
      .list-group {
        position: relative;
        z-index: 400;
        /* CSS specific to iOS devices to solve zoom bug */ } }
    @media (max-width : 768px) {
      input {
        font-size: 16px; } } }

  .card {
    .card-img-top {
      max-height: 50px;
      margin-top: 1em; }
    .card-body {
      padding: 0.25em; } }

  .back-link {
    font-size: $small-font-size;
    color: $secondary; } }
