.hero-carousel-slide {
  background-image: linear-gradient(to right, rgb(174, 223, 251), rgb(75, 174, 209));
  position: relative;
  @include media-breakpoint-up(lg) {
    height: 400px;
    overflow: hidden;
    border-radius: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    &.bg-dark {
      background-image: linear-gradient(to right, #000000, #323435);
      color: $white;
      .hero__bg {
        opacity: 0.65; } } }
  &--content {
    border: "5px solid red";
    @include media-breakpoint-down(lg) {
      text-align: center;
      height: 300px;
      padding-top: 1rem;
      h1, .h1 {
        font-size: 1.2rem; } }
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 10%;
      width: 90%;
      margin-left: 5%;
      h1, .h1 {
        font-size: 1.8rem; }
      p {
        font-size: 0.9rem; } } }
  @include media-breakpoint-up(lg) {
    .bg-dark-fadeout-right, .bg-dark-fadeout-left {
      display: block;
      width: 100%;
      position: absolute;
      height: 100%;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0,0,0,0.9) 40%, rgba(0,0,0,0.6) 50%, rgba(0,0,0,0) 70%); }
    .bg-dark-fadeout-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8) 0%, rgba(0,0,0,0.7) 20%, rgba(0,0,0,0.6) 40%, rgba(0,0,0,0) 60%); }
    .bg-light-fadeout-right, .bg-light-fadeout-left {
      display: block;
      width: 100%;
      position: absolute;
      height: 100%;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 70%); }
    .bg-light-fadeout-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, rgba(0,0,0,0.1) 20%, rgba(0,0,0,0.06) 40%, rgba(0,0,0,0) 60%); }
    .bg-blue-fadeout-right, .bg-blue-fadeout-left {
      display: block;
      width: 100%;
      position: absolute;
      height: 100%;
      background-image: linear-gradient(to right, rgba(174, 223, 251, 0.9) 0%, rgba(174, 223, 251,0.9) 40%, rgba(174, 223, 251,0.6) 50%, rgba(174, 223, 251,0) 70%); }
    .bg-blue-fadeout-left {
      background-image: linear-gradient(to left, rgba(75, 174, 209, 0.9) 0%, rgba(75, 174, 209,0.9) 40%, rgba(75, 174, 209,0.6) 50%, rgba(75, 174, 209,0) 70%); } }

  .hero__bg {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0; }
    @include media-breakpoint-down(lg) {
      height: 300px; } } }
@include media-breakpoint-up(lg) {
  .text-lg-white {
    color: $white; } }
@include media-breakpoint-down(md) {
  .text-md-white {
    color: $white; } }

.hero {
  @include media-breakpoint-up(lg) {
    height: 400px;
    max-height: 400px; }
  position: relative;
  &__bg {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    @include media-breakpoint-up(lg) {
      max-height: 400px;
      width: 100%;
      margin-right: 0; }
    @include media-breakpoint-down(md) {
      max-height: 300px;
 }      // top: 5%
    @include media-breakpoint-down(sm) {
      width: 130%;
      margin-right: 0;
 }      // top: 10%
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }

  &__content {
    position: relative;
    padding-top: 13vh;
    max-height: 600px;
    @include media-breakpoint-down(md) {
      padding-top: 2rem; }
    @include media-breakpoint-up(lg) {
      height: 80vh; }
    width: 100%;
    h1 {
      color: $dark;
      @include media-breakpoint-down(sm) {
        font-size: 1.5rem; }
      @include media-breakpoint-down(md) {
        max-width: 70%;
        position: absolute; } }
    p {
      @include media-breakpoint-down(md) {
        margin-top: 15rem; } }
    &__ctas {
      @include media-breakpoint-up(lg) {
        margin-top: 2rem; } } }
  &__navigation {
    font-weight: 600;
    font-size: 110%;
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 0;
      width: 100%; }
    @include media-breakpoint-down(md) {
      margin-top: 1rem; }
    .d-flex {
      justify-content: center;
      flex-wrap: wrap; }
    a {
      padding: 0.5rem 1rem;
      color: $dark;
      text-transform: capitalize; } } }
