.btn-default {
  text-shadow: 0 1px 0 #fff;
  background-image: -webkit-gradient(linear,left 0,left 100%,from(#fff),to(#e0e0e0));
  background-image: -webkit-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: -moz-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff',endColorstr='#ffe0e0e0',GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  &:active, &.active {
    background-color: #e0e0e0;
    border-color: #dbdbdb;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    font-weight: bold; } }

input.no-spin-buttons::-webkit-inner-spin-button, input.no-spin-buttons::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input.no-spin-buttons {
  -moz-appearance: textfield !important; }

.semi-small {
  font-size: 90%;
  font-weight: 400; }

.text-remark {
  font-size: $small-font-size;
  color: $gray-600; }

.text-bold {
  font-weight: bold; }

.flex-grow-1 {
  -ms-flex: 1 1; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

.nav-link {
  cursor: pointer; }

.pointer-light:hover {
  cursor: pointer;
  background-color: $gray-100; }

.pointer-primary:hover {
  cursor: pointer;
  color: $white;
  background-color: $primary;
  .text-secondary {
    color: $ultralight-gray !important; } }

.hover-scale-img {
  img {
    transition: transform .25s ease; } }
.hover-scale-img:hover {
  img {
    transform: scale(1.05); } }

.sk-loading {
  position: relative;
  height: 100vH;
  background-color: $gray-300;
  overflow: hidden;
  &.sk-bg500 {
    background-color: $gray-500; }
  &.sk-bg400 {
    background-color: $gray-400; }
  &.sk-bg200 {
    background-color: $gray-200; }
  &.sk-b30 {
    height: 30vH;
    min-height: 300px; }
  &.sk-b10 {
    height: 10vH;
    min-height: 100px; }
  &.sk-b50 {
    height: 50vH;
    min-height: 500px; }
  &.h1 {
    height: 30px;
    width: 60%; }
  &.sk-line {
    height: 14px;
    width: 80%;
    margin-top: 5px; }
  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .4), transparent);
    animation: skeleton-loading 1.5s infinite; } }

@keyframes skeleton-loading {
  100% {
    transform: translateX(100%); } }

/* CSS specific to iOS devices to solve zoom bug */
@media (max-width : 768px) {
  input {
    font-size: 16px !important; } }

.content-image {
  max-width: 100%; }

.mt-md-8 {
  @include media-breakpoint-down(md) {
    margin-top: 8rem !important; } }

.shadow-md-0 {
  @include media-breakpoint-down(md) {
    box-shadow: none !important; } }

.checkout-sidebar {
  @include media-breakpoint-up(md) {
    position: fixed;
    right: 0;
    z-index: 999;
    margin-top: 1rem; }

  &.checkout-sidebar--step-address {
    @include media-breakpoint-up(md) {
      margin-top: 0rem; } }

  &.checkout-sidebar--step-confirm {
    @include media-breakpoint-up(md) {
      margin-top: 3rem; } } }

.breadcrump {
  font-size: 0.8rem; }

.react-multi-carousel-dot button {
  width: 24px;
  height: 6px;
  border-radius: 2px;
  border: 0; }

.react-multi-carousel-dot--active button {
  background: $primary; }

.fileeditor__preview {
  background-color: transparent; }

.inpage-configurator--mobile-visible {
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 15px;
  background: #fff;
  z-index: 1050;
  padding-bottom: 8rem;
  .fileupload {
    padding: 0;
    background-color: transparent; }
  .inpage-configurator--nav-bar {
    flex-wrap: nowrap;
    overflow-x: auto;
    overscroll-behavior: contain;
    border: 0;
    display: flex;
    margin-left: -15px;
    margin-right: 3rem;
    height: 3rem;
    scrollbar-width: thin;
    scrollbar-color: $light-gray $body-bg;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
      z-index: 1;
      backdrop-filter: blur(1px);
      height: 3rem;
      width: 2rem;
      margin-right: 3rem; }

    @include media-breakpoint-down(lg) {
      &::-webkit-scrollbar {
        height: 11px; }
      &::-webkit-scrollbar-track {
        margin-left: 12px;
        margin-right: 12px; }
      &::-webkit-scrollbar {
        height: 11px; }
      &::-webkit-scrollbar-track {
        background: $body-bg; }
      &::-webkit-scrollbar-thumb {
        background-color: $light-gray;
        border-radius: 6px;
        border: 3px solid $body-bg; } }
    li.nav-item {
      flex: 0 0 auto; } }
  .inpage-configurator--step-navigator {
    position: fixed;
    bottom: 2rem;
    height: 7rem;
    background: $gray-100;
    width: 100%;
    padding: 10px;
    margin-left: -15px;
    margin-right: -15px; }
  &-summary-open {
    .inpage-configurator--nav-bar {
      &::before {
        background: transparent; } }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(1px); } } }

.inpage-configurator--summary {
  position: fixed;
  height: 5.1rem;
  max-width: 100%;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 2rem;
  background: $white;
  border-radius: 1rem 1rem 0rem 0rem;
  padding-top: 0.5rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5) !important;
  transition: all 0.75s ease;
  &-open {
    height: 80vH;
    z-index: 2;
    overflow: auto; } }
