#support-contact-form {
  .form-step-option-card {
    min-height: 8rem;
    label {
      display: block;
      cursor: pointer; }
    &_input {
      opacity: 0;
      width: 1px;
      margin: 0;
      padding: 0; }
    &.card-success {
      background-color: rgba($green, 0.5);
      border-color: rgba($green, 0.5);
      cursor: pointer;
      transition: all ease 200ms; }
    &:hover {
      transform: scale(1.03);
      box-shadow: $box-shadow; } }
  #message {
    height: 150px; }
  .steps .step,
  .steps .step {
    width: 100%;
    margin-bottom: 35px;
    text-align: center; }
  .steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    text-align: center; }
  .steps .step .step-icon-wrap::before,
  .steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -1px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1; }
  .steps .step .step-icon-wrap::before {
    left: 0; }
  .steps .step .step-icon-wrap::after {
    right: 0; }
  .steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    line-height: 75px;
    z-index: 5;
    .creditCard {
      fill: #fff;
      stroke: #fff; } }
  .steps .step .step-title {
    margin-top: 16px;
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500; }
  .steps .step:first-child .step-icon-wrap::before {
    display: none; }
  .steps .step:last-child .step-icon-wrap::after {
    display: none; }
  .steps .step.completed .step-icon-wrap::before,
  .steps .step.completed .step-icon-wrap::after {
    background-color: $primary; }
  .steps .step.completed .step-icon {
    border-color: $primary;
    background-color: $primary;
    color: #fff;
    fill: #fff; }
  #imgCol {
    min-width: 98px; }

  @media (max-width: 576px) {
    .shipping-vendor-logo {
      max-width: 69px;
      max-height: 37px; }
    #imgCol {
      max-width: 100px; }
    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
      display: none; } }

  @media (max-width: 768px) {
    .notCurrent {
      display: none; }
    .current {
      display: block; }
    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
      display: none; } }

  @media (max-width: 991px) {
    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
      display: none; } }

  @media (max-width: 1200px) {
    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
      display: none; } }

  .shipping-vendor-logo {
    max-height: 7vh;
    object-fit: contain; } }
