$black: #000000;
$white: #ffffff;

//gray
$ultralight-gray: #dedede;
$light-gray: #adb6bc;
$gray: #808080;
$account-backgroung-gray: #eeeeee;
$vertical-separator-gray: #e6e5e5;
//green
$light-green: #0ee00e;
//blue
$blue: #032b53;
$light-blue: #1087f3;
$selected-blue: #337ab7;

$navbar-height: 62px;
$topbar-height: 38px;
