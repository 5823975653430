.rpm-container {
  height: 100vh;
  min-height: 640px; }

.rpm-scroller {
  height: 100%;
  min-height: 640px;
  overflow-y: scroll;
  position: relative; }

.rpm-scroller-inner {
  position: relative; }

.rpm-container {
  position: relative;
  overflow: hidden;
  /*background: #34495e;*/ }

.rpm-menu-trigger {
  position: relative;
  padding-left: 60px;
  font-size: 0.9em;

  &:before {
    position: absolute;
    top: 2px;
    left: 0;
    width: 40px;
    height: 6px;
    background: #fff;
    box-shadow: 0 6px #34495e, 0 12px #fff, 0 18px #34495e, 0 24px #fff;
    content: ''; } }

.rpm-mp-pusher {
  position: relative;
  left: 0;
  height: 100%; }

.rpm-mp-menu {
  position: absolute;
  pointer-events: auto;
  /* we can't use fixed here :(*/
  top: 0;
  left: 0;
  z-index: 1;
  width: 300px;
  height: 100%;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  &.cart-mp-menu {
    left: auto;
    right: 0;
    width: 500px;
    max-width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5); } }

.rpm-mp-level {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

/* overlays for pusher and for level that gets covered */

.rpm-mp-pusher::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  content: '';
  opacity: 0; }

.rpm-mp-level {
  &::after, &::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    opacity: 0; } }

.rpm-mp-pusher::after {
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s; }

.rpm-mp-level::after {
  background: rgba(0, 0, 0, 0.3);
  -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  z-index: -1; }

.rpm-mp-pusher.rpm-mp-pushed::after {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s; }

.rpm-mp-level.rpm-mp-level-overlay {
  &::after {
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s; }

  cursor: pointer;

  &.rpm-mp-level::before {
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1; } }

.rpm-mp-pusher, .rpm-mp-level {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s; }

/* overlap */

.rpm-mp-overlap .rpm-mp-level.rpm-mp-level-open {
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(-40px, 0, 0);
  -moz-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0); }

/* First level */

.rpm-mp-menu {
  > .rpm-mp-level, &.rpm-mp-overlap > .rpm-mp-level {
    box-shadow: none;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    &.rpm-mp-level-open {
      box-shadow: none;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); } } }

/* cover */

.rpm-mp-cover .rpm-mp-level.rpm-mp-level-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  > ul > li > .rpm-mp-level:not(.rpm-mp-level-open) {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

/* content style */

.rpm-mp-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none; }

  .rpm-mp-header, h2 {
    margin: 0;
    padding: 1em;
    color: rgba(0, 0, 0, 0.4);
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    font-weight: 300;
    font-size: 2em; } }

.rpm-inline-block {
  display: inline-block; }

.rpm-node-link {
  width: 80%;
  cursor: pointer;
  &.nav-link-pill {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 0.5rem; } }

.rpm-node-exp {
  /*width:10%; */
  font-size: 20px;
  cursor: pointer;
  padding: 0.7em 1em 0.7em 1em;
  color: rgba(0, 0, 0, 0.5);

  i {
    /*position: relative;*/
    top: 8px; }

  &:hover {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0); } }

.rpm-mp-menu {
  &.rpm-mp-overlap h2::before {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 8px;
    font-size: 75%;
    line-height: 1.8;
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.1s 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.1s 0.3s;
    transition: opacity 0.3s, transform 0.1s 0.3s;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%); }

  &.rpm-mp-cover .rpm-mp-header .rpm-mp-menu.rpm-mp-cover h2 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1em; } }

.rpm-mp-overlap .rpm-mp-level.rpm-mp-level-overlay > h2::before {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0); }

.rpm-mp-menu ul li {
  > .rpm-node-cntr {
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);

    > a {
      /*display: block;*/
      padding: 0.7em 1em 0.7em 1.8em;
      outline: none;

      /*box-shadow: inset 0 -1px rgba(0,0,0,0.2);*/
      text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
      font-size: 1.4em;
      -webkit-transition: background 0.3s, box-shadow 0.3s;
      -moz-transition: background 0.3s, box-shadow 0.3s;
      transition: background 0.3s, box-shadow 0.3s; } }

  &::before {
    position: absolute;
    left: 10px;
    z-index: -1;
    color: rgba(0, 0, 0, 0.2);
    line-height: 3.5; } }

.rpm-mp-level > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2), inset 0 1px rgba(0, 0, 0, 0.2); }

.rpm-mp-menu ul li a:hover, .rpm-mp-level > ul > li:first-child > a:hover {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0); }

.rpm-mp-menu .rpm-mp-level.rpm-mp-level-overlay > ul > li > a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0); }

.rpm-mp-level {
  &.rpm-mp-level-overlay {
    > ul > li:first-child > a {
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0); }

    > ul > li:first-child > a {
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0), inset 0 1px rgba(0, 0, 0, 0); } }

  > ul > li:first-child > a:hover {
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0), inset 0 1px rgba(0, 0, 0, 0); } }

/* seems like Chrome 34.0.1847.131 needs the second shadow otherwise the transition breaks */

.rpm-back-item {
  padding: 0 10px; }

span.rpm-back-item svg {
  font-size: 10px; }

.rpm-mp-back {
  > .link {
    padding: 1em;
    outline: none;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    display: block;
    font-size: 0.8em;
    position: relative;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    transition: background 0.3s; }

  background: rgba(0, 0, 0, 0.1);
  outline: none;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  display: block;
  font-size: 0.8em;
  position: relative;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  transition: background 0.3s;

  svg {
    font-size: 20px; } }

.rpm-mp-menu .rpm-mp-level.rpm-mp-level-overlay > .rpm-mp-back {
  background: transparent;
  box-shadow: none;
  color: transparent;

  &::after {
    background: transparent;
    box-shadow: none;
    color: transparent; } }

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
/* We'll show the first level only */

.no-csstransforms3d .rpm-mp-pusher, .no-js .rpm-mp-pusher {
  padding-left: 300px; }

.no-csstransforms3d .rpm-mp-menu .rpm-mp-level, .no-js .rpm-mp-menu .rpm-mp-level {
  display: none; }

.no-csstransforms3d .rpm-mp-menu > .rpm-mp-level, .no-js .rpm-mp-menu > .rpm-mp-level {
  display: block; }
