.checkoutcart {
  &__lineitems {
    &--notfullscreen {
      max-height: 66vh;
      @include media-breakpoint-down(sm) {
        max-height: 55vh; } } } }
.amazon-pay {
  #addressBookWidgetDiv, #walletWidgetDiv {
    display: none;
    height: 0;
    width: 0; } }
