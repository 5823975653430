/* general editor form (also for file editor) */
.fileeditor, .texteditor {
  $submit-height: 80px;
  max-width: 1400px;
  min-height: calc(100vh - #{$navbar-height} - #{$topbar-height});
  &__row {
    min-height: calc(100vh - #{$navbar-height} - #{$topbar-height}); }
  &__preview {
    background-color: $gray-100; }
  &__sidebar {
    z-index: 1;
    box-shadow: 2px 0px 3px $gray-300;
    padding-bottom: $submit-height;
    &.shadow-left {
      box-shadow: -2px 0px 3px $gray-300; }
    &__heading {
      background: $gray-300; }
    &__linesetting, &__linesettinglabels {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid $gray-300;
      &__left {
        width: 30px; }
      &__right {
        flex-grow: 1; }
      &__1, &__2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
      &__1 {
        padding-bottom: 0.5rem; }
      .form-group {
        margin-bottom: 0; }
      &__button {
        height: 37px; }
      &__colorselection {
        min-width: 60px;
        .Select-menu-outer {
          min-width: 250px; } }
      &__fontselection {
        min-width: 80px;
        .Select-menu-outer {
          min-width: 250px; } }
      &__fontSize {
        max-width: 60px; }
      &__lineheight {
        max-width: 60px; } }
    &__helpbutton {
      color: white; }
    &__submit {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: $submit-height;
      background-color: lighten($primary, 20%);
      &__amount {
        width: 20%;
        .form-group {
          margin-bottom: 0; } }
      &__times {
         width: 10%; }
      &__button {
        width: 70%;
        button {
          border-radius: 0;
          height: calc(#{$submit-height} - 1rem);
          margin: auto; } } }
    .editorformsection {
      border: 0;
      &__header {
        background-color: $white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        &__title {
          padding: 0.75rem 1rem;
          cursor: pointer;
          &.keepOpen {
            cursor: default;
            &:hover {
              h2 {
                color: $gray-600; } } }
          &:hover {
            h2 {
              color: $gray-700; } }
          color: $gray-600;
          h2 {
            font-family: $font-family-base;
            font-size: $font-size-base; }
          .fa {
            margin-right: 10px;
            font-weight: normal;
            font-size: $small-font-size; } } }
      &__help {
        padding: 0.75rem 1rem;
        &__button {
          cursor: pointer;
          color: white; } }
      &__body {
        padding: 1rem;
        background-color: $gray-200; } } }
  &__sidebar {
    &__submit {
      position: sticky;
      box-shadow: 0px -1px 3px $gray-600; } }

  .editor-from--warnings {
    .alert {
      border: 0;
      background: rgba(0,0,0,0);
      font-size: $small-font-size;
      margin: 0;
      padding: 0; }
    .alert-success {
      color: $success;
      &:before {
        content: '✔️';
        color: $success; } }
    .alert-warning {
      color: $warning;
      &:before {
        content: '⚠️'; } }
    .alert-danger {
      color: $danger;
      &:before {
        content: '❌'; } } } }

/* CSS specific to iOS devices to solve zoom bug */
@media (max-width : 768px) {
  .fileeditor, .texteditor {
    &__textarea, input {
      font-size: 16px; }
    &__sidebar {
      &__submit {
        position: fixed;
        box-shadow: 2px 0px 3px $gray-600; } } } }

