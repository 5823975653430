.faqcatgegory {
  &-faqlist {
    list-style: none;
    a {
      padding-top: 2em;
      padding-left: 1em;
      padding-right: 1em;
      border-bottom: 1px solid $gray-300;
      display: block;
      &:hover {
        background-color: $gray-200;
        text-decoration: none; } }
    &-faq {
      color: $secondary; }
    &-more {
      display: block;
      text-align: right;
      &:hover {
        text-decoration: underline; } } } }
