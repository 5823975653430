@import "./PushMenu";
.navigationbar {
  font-size: 90%;
  .navbar {
    height: $navbar-height; }
  z-index: 1020;
  position: relative;
  height: $navbar-height;
  width: 100vw;
  background-color: $white;
  transition: background-color 500ms linear;
  box-shadow: $box-shadow-sm;
  &__useractions {
    order: 3;
    a {
      color: $navbar-light-color;
      min-width: 55px;
      &:hover {
        color: $navbar-light-hover-color; } } }
  &--sticky {
    background-color: $white; }
  .navbar-brand {
    padding: 0;
    img {
      @media (max-width: 360px) {
        width: 100px;
        height: 29px; } } }
  &__links {
    width: 100%;
    &__submenu {
      display: none; } }
  .nav-link {
    font-weight: 600; }
  .nav-item .nav-link-pill {
    border-radius: 2rem;
    background-color: $success;
    color: $white;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    @include media-breakpoint-down(lg) {
      margin-top: 0.5rem; }
    &:hover {
      background-color: darken($success, 7.5%);
      color: $white; } }
  .nav-item.hover {
    >.navigationbar__links__submenu {
      .nav-link-arrow {
        display: inline-block;
        float: right; }
      .nav-item.hover {
        background-color: $gray-300; }
      display: block;
      position: absolute;
      left: 2rem;
      right: 2rem;
      top: $navbar-height;
      background-color: $white;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      min-height: 306px;
      .navigationbar__links__submenu {
        left: 300px;
        top: 0;
        right: 0;
        min-height: 100%;
        .navigationbar__links__submenu_items {
          background-color: rgb(248, 249, 250); }
        .navigationbar__links__submenu {
          li {
            height: 34px;
            .nav-link {
              padding: 8px 0.5rem; } } } }
      ul {
        list-style: none;
        padding-inline-start: 0;
        li {
          width: 300px; } }
      .nav-teaser {
        width: 270px;
        margin: 1rem;
        position: relative;
        transition: box-shadow .75s ease;
        &:hover {
          box-shadow: $box-shadow-sm; }
        &__link {
          .btn-link {
            font-weight: 600;
            float: right; }
          text-decoration: none;
          color: $dark; } } } }
  .navbar .navbar-nav {
    @media (max-width: 1199px) {
      display: none; }
    .nav-link {
      @include media-breakpoint-up(xl) {
        margin: 0 0.25rem 0 0rem;
        &:not(.nav-link-pill) {
          padding: 1.313rem 0.5rem; }
        &.nav-link-pill {
          margin: .8rem .25rem .8rem 0; } } }
    .navigationbar__links__submenu {
      .nav-link {
         padding: 1rem 0.5rem; } } } }
.navplaceholder {
  height: $navbar-height;
  width: 100%;
  background-color: $dark;
  position: fixed;
  top: 0;
  z-index: 1019; }
.navplaceholder:after {
  position: relative;
  height: 70px; }
.recipedisplay {
  padding-top: 8rem; }

.navbar-dark .navbar-nav .dropdown-menu.show .nav-link {
  color: $body-color; }

.navbar-collapse {
  @include media-breakpoint-down(lg) {
    margin: 0.5rem -1rem;
    background-color: $white;
    padding: 1rem;
    order: 4;
    .navbar-nav {
      .show > .nav-link, .active > .nav-link, .nav-link.show, .nav-link.active, .nav-link:focus {
        color: $dark; } } } }

.navbar-nav .dropdown-menu .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.nav-link-arrow {
  display: none; }

.mobile-menu {
  display: none;
  @include media-breakpoint-down(lg) {
    display: block;
    .hamburg {
      width: calc(#{$navbar-height} - 1rem);
      height: calc(#{$navbar-height} - 1rem);
      position: relative;
      margin-left: auto;
      margin-right: auto;
      transition: border-radius .5s; }
    .line {
      position: absolute;
      left: 8px;
      height: 4px;
      width: 30px;
      background: $dark;
      display: block;
      transition: 0.5s;
      transform-origin: center;
      border-radius: 2px; }
    .line:nth-child(1) {
      top: 12px; }
    .line:nth-child(2) {
      top: 21px; }
    .line:nth-child(3) {
      top: 30px; }
    &.active .hamburg .line:nth-child(1) {
      transform: translateY(9px) rotate(-45deg); }
    &.active .hamburg .line:nth-child(2) {
      opacity: 0; }
    &.active .hamburg .line:nth-child(3) {
      transform: translateY(-9px) rotate(45deg); } } }

.sidebar-cart {
  z-index: 2020;
  display: block;
  position: fixed;
  height: calc(100vh - #{$topbar-height});
  width: 500px;
  max-width: 100%;
  right: 0;
  top: 0; }

.navigationbar--sticky {
  .sidebar-cart {
    height: 100vh; } }

.sticky {
  &--wrapper {
    position: relative;
    > div:first-of-type {
      position: relative;
      z-index: 1050; } }
  z-index: 1030; }
