.faqs {
  &-hero {
    position: relative;
    overflow-y: scroll;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
      text-align: center;
      color: $white;
      margin-top: 4rem;
      @include media-breakpoint-down(md) {
        font-size: ($font-size-base * 3); }
      @media only screen and (min-width: 1281px) {
        margin-top: 15rem; } } }

  &-refresh {
    margin-bottom: 1rem;
    @media only screen and (min-width: 1281px) {
      margin-top: 3.5rem; }
    i {
      float: left;
      margin-top: 5px; } } }

.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }
