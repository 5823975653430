.cmsblock {
  &__split {}

  &__full {}

  &__teaser,  &__small_tile {
    @include media-breakpoint-down(md) {
      box-shadow: $box-shadow; }
    transition: box-shadow .25s ease;
    img {
      transition: transform .25s ease; }
    a {
      overflow: hidden;
      font-weight: 600; }
    &:hover {
      box-shadow: $box-shadow;
      img {
        transform: scale(1.025); } }
    margin-bottom: 2rem;
    height: calc(100% - 2rem);
    border-color: $gray-200;
    &__content {
      flex-grow: 1; } }

  &__small_tile {
    .product-title {
      color: #000 !important; } } }
